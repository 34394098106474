.App {
  text-align: center;
  background: rgb(41,43,44);
  background: linear-gradient(0deg, rgba(41,43,44,1) 10%, rgba(1,174,238,1) 50%, rgba(41,43,44,1) 90%);
  min-height: 100vh;
  flex-direction: column;
}

.App-header {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.shadow{
  margin: 5px auto 5px auto;
}

#navbar-dapp{
  height:60px
}

.connectBtnNav{
  margin-left: auto;
}

#connectBtn{
  font-family:monospace;
  border-radius: 10px;
  background: #01aeee;
  border-color: #01aeee;
  color: #000;
}

.joinModal .modal-content{
  background-color: #292b2c;
  color: #fff;
}

.cardTextPoolInfo{
  font-size: 15;
  font-weight: 300;
}